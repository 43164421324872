<template>
  <div v-if="room != null">
    <div style="display: flex; flex-direction: column">
      <div class="chatheader"></div>
      <div style="flex-grow: 1" class="chatbox">
        <div class="centercontent" v-if="loading"></div>
        <div
        style="height:calc(100vh - 160px)"
          class="centercontent"
          v-else-if="room.chat == null || room.chat.length == 0"
        >
        Empty chat with {{room.remote.first_name}}, Start Writing
        </div>
         <perfect-scrollbar
         v-else 
      class="scroll " id="chat-window"
      style="height:calc(100vh - 180px);position: relative"
    >
          <div class="chatarea " style="height:inherit">
            <div v-for="msg in room.chat" :key="msg.timestamp" class="msg mb-3" 
            :class="msg.from == room.remote.external_id ? 'remote blue lighten-3' : 'local primary'">
              {{msg.msg}}
            </div>
          </div>
         </perfect-scrollbar>
      </div>
      <div  v-if="!loading" class="chattext mt-3">
        <v-text-field v-model="msg" hide-details
        @keydown.enter.exact.prevent
          @keyup.enter.exact="sendmsg"
           single-line rounded filled label="Write here" >
           <template v-slot:append>
          <v-btn class="m-0 p-0" icon  @click="sendmsg">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </template>
        </v-text-field>
      </div>
    </div>
  </div>
  <div style="height:calc(100vh - 80px)" v-else class="centercontent">
    Select A conversation or start a new one
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      snackbar: false,
      msg: "",
      langs: {},
      room: null,
    };
  },
  methods: {
    saveRoom()
    {
      this.$chat.saveRoom(this.room.users, this.room.key, this.room.title);
    },
    sendmsg()
    {
      
      const tosend = {from: this.$user.getCurrentUser().external_id, msg:this.msg, timestamp:Math.floor(Date.now() / 1000)}
      if(! this.room.chat)
      {
        console.log("no chat creating room")
        this.room.chat = [];
        this.saveRoom();
      }
      const encrypted = this.$chat.encryptMsg(tosend);
      console.log("encrypted msg",encrypted)
      //this.room.chat.push(tosend)
      console.log("chats are",this.room.chat)
      this.$gun.get(`chat/${this.room.key}`).set(encrypted)
      this.$util.getEmitter().emit(this.room.key,JSON.stringify({msg: tosend}))
      //this.$util.EventBus.$emit("setChatHistoryToRoom", {key:this.room.key,chat:this.room.chat})
      this.msg = ""
    }
  },
  mounted() {
    if(this.$chat.getActiveRoom() != null)
      this.room = this.$chat.getActiveRoom();
    this.$util.EventBus.$on("chatRoomChange", () => {
      console.log(
        "chatroom changed and it is",
        this.$chat.getActiveRoom(),
        "external id",
        this.$chat.getActiveRoom().remote.external_id
      );
      this.$util.getEmitter().subscribe(this.$chat.getActiveRoom().key, () =>{
       
       let objDiv = document.getElementById('chat-window');
        this.$nextTick(() => {
          objDiv.scrollTop = objDiv.scrollHeight;
        })
          this.$forceUpdate();
        
      })
      if(! this.$chat.getActiveRoom().id)
      this.$util
        .getEmitter()
        .emit(
          this.$chat.getActiveRoom().remote.external_id,
          JSON.stringify({addChatRoom: this.$chat.getActiveRoom()})
        );
      this.room = this.$chat.getActiveRoom();
    });
  },
  created() {
    this.$root.title = "Chat";
    this.$root.admin.extramargin = "390px";
    this.$root.admin.openside = true;
    this.$root.currentpage = "chat";
  },
};
</script>

<style  scoped>

.theme--light >>> .v-input__append-inner
{
  margin:0px;
  align-self: center;
}
</style>>
